import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/system/Box";
import Swal from "sweetalert2";
import BtnCus from "../../../Components/Btn/BtnCus";
import Stack from "@mui/material/Stack";
import PaginationCustom from "../../../Components/PaginationCustom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Paper, Typography } from "@mui/material";
import { useStockStore } from "./store/stock";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ModalAddStock from "../../../Components/ModalAddStock";

export default function Stock() {
  const navigate = useNavigate();
  const { id, name } = useParams();
  const [state, action] = useStockStore();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    action.updateStore({
      page: 0,
      search: "",
    });
    action.loadData(id);
  }, []);

  async function hapus(idC) {
    let modal = await Swal.fire({
      title: "Yakin data ini Dihapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus",
      cancelButtonText: "Batal",
    });
    if (modal.isConfirmed) {
      Swal.showLoading();
      const res = await action.deleteData(idC);
      if (res) {
        let dat = await action.loadData();
        if (dat) {
          Swal.fire({
            title: "Sukses hapus data",
            timer: 2000,
            icon: "success",
          });
        }
      }
    }
  }

  return (
    <Paper sx={{ p: "20px", borderRadius: "20px", mt: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => navigate("/loged/logistik")}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" ml="12px">
           Data Stock {name ?? "-"}
          </Typography>
        </Box>

        <BtnCus
          variant="contained"
          text="Tambah Stock"
          onClick={() => setOpenModal(true)}
        />
      </div>
      <TableContainer sx={{ mt: "20px", borderRadius: "20px" }}>
        <Table size="small">
          <TableHead sx={{ bgcolor: "#f2edf5" }}>
            <TableRow>
              {["No", "Tanggal", "Jumlah", "Keterangan", "Aksi"].map((e, i) => (
                <TableCell key={i} align="center" sx={{ fontWeight: 700 }}>
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!state.data ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Load data . . .
                </TableCell>
              </TableRow>
            ) : state.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              state.data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="center">
                    {state.page * 10 + i + 1}
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    {row.date ? moment(row.date).format("DD/MM/YYYY") : "-"}
                  </TableCell>
                  <TableCell align="center">{row.total ?? 0} </TableCell>
                  <TableCell align="center">{row.note ?? "-"}</TableCell>
                  <TableCell align="center">
                    <Stack spacing={1} direction="row" justifyContent="center">
                      <BtnCus
                        style={{ fontSize: "12px" }}
                        variant="contained"
                        text="Hapus"
                        color="error"
                        size="small"
                        onClick={() => hapus(row.id)}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" my="20px">
        <PaginationCustom action={action} state={state} />
      </Box>
      <ModalAddStock
        open={openModal}
        action={action}
        close={() => setOpenModal(false)}
        id={id}
      />
    </Paper>
  );
}
