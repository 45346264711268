import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

export default function ModalAddDistri(props) {
  const [note, setNote] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [date, setDate] = useState(null);
  const [total, setTotal] = useState(null);

  async function add(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await props.action.addData({
      idAsset: props.id,
      date,
      total,
      note,
      receiver,
    });

    if (res.ok) {
      await props.action.loadData(props.id);
      setNote(null);
      setDate(null);
      setTotal(null);
      setReceiver(null);
      props.close();
      Swal.fire({
        title: "Sukses tambah stock",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Tambah stock",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Tambah Distribusi
          <Divider sx={{ mt: "12px" }} />
        </DialogTitle>
        <form onSubmit={add}>
          <DialogContent>
            <Stack spacing={2}>
              <TextInput
                label="Penerima"
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
                placeholder="Tulis Penerima Logistik"
              />
              <TextInput
                label="Jumlah"
                type="number"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
                placeholder="Tulis Total Stock"
              />
              <TextInput
                label="Tanggal"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <TextInput
                label="Keterangan"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Tulis Keterangan"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={props.close}>
              Batal
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
