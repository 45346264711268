import axios from 'axios';
// axios.defaults.withCredentials = true;
const baseApi = process.env.REACT_APP_SERVER;
const instance = axios.create({
  baseURL: baseApi,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    'x-api-keyyy': process.env.REACT_APP_APIKEY
  },
});

export const apiClient = instance;
