import "./Assets/scss/style.scss";
import React, { Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import Load from "./Components/Load";
import { useLoginStore } from "./Views/Auth/store";
import Privacy from "./Views/Pages/Privacy";
import Disclaimer from "./Views/Pages/Disclaimer";
import Support from "./Views/Pages/Support";
import Stock from "./Views/Pages/Logistik/Stock";
import Distribution from "./Views/Pages/Logistik/Distribution";
const AddPemilih = lazy(() => import("./Views/Pages/Pemilih/AddPemilih"));
const Login = lazy(() => import("./Views/Auth/Login"));
const Index = lazy(() => import("./Views/Pages/Index"));
const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard"));
const Pengguna = lazy(() => import("./Views/Pages/Pengguna/Pengguna"));
const TambahPengguna = lazy(() => import("./Views/Pages/Pengguna/AddPengguna"));
const EditPengguna = lazy(() => import("./Views/Pages/Pengguna/EditPengguna"));
const Pemilih = lazy(() => import("./Views/Pages/Pemilih/Pemilih"));
const EditPemilih = lazy(() => import("./Views/Pages/Pemilih/EditPemilih"));
const ImportPemilih = lazy(() => import("./Views/Pages/Pemilih/ImportPemilih"));
const DetailPemilih = lazy(() => import("./Views/Pages/Pemilih/DetailPemilih"));
const Relawan = lazy(() => import("./Views/Pages/Relawan/Relawan"));
const AddRelawan = lazy(() => import("./Views/Pages/Relawan/AddRelawan"));
const EditRelawan = lazy(() => import("./Views/Pages/Relawan/EditRelawan"));
const MasterData = lazy(() => import("./Views/Pages/MasterData/MasterData"));
const Update = lazy(() => import("./Views/Pages/update/Update"));
const Quickcount = lazy(() => import("./Views/Pages/Quickcount1/Quickcount"));
const AddQuickcount = lazy(() =>
  import("./Views/Pages/Quickcount1/AddQuickCount")
);
const DataTPS = lazy(() => import("./Views/Pages/DataTPS/DataTPS"));
const Agenda = lazy(() => import("./Views/Pages/Agenda"));
const Demografi = lazy(() => import("./Views/Pages/Demografi"));
const Logistik = lazy(() => import("./Views/Pages/Logistik"));
const Polling = lazy(() => import("./Views/Pages/Polling"));
const WaBlast = lazy(() => import("./Views/Pages/Wablast/Wablast"));
const WaBlastDetail = lazy(() =>
  import("./Views/Pages/Wablast/DetailBroadcast")
);

const pages = [
  { el: <Demografi />, url: "demografi" },
  { el: <WaBlastDetail />, url: "wa-blast/:id" },
  { el: <WaBlast />, url: "wa-blast" },
  { el: <Agenda />, url: "agenda" },
  { el: <Logistik />, url: "logistik" },
  { el: <Stock />, url: "logistik/stock/:name/:id" },
  { el: <Distribution />, url: "logistik/distribution/:name/:id" },
  { el: <Logistik />, url: "logistik" },
  { el: <Polling />, url: "polling" },
  { el: <Support />, url: "support" },
  { el: <div>help</div>, url: "help" },
  { el: <Privacy />, url: "privacy" },
  { el: <Disclaimer />, url: "disclaimer" },

  { el: <Pemilih />, url: "database" },
  { el: <DataTPS />, url: "database/tps" },
  { el: <AddPemilih />, url: "database/pemilih/add" },
  {
    el: <RoleComp el={<MasterData />} role={["1"]} />,
    url: "data-wilayah",
  },
  {
    el: <Update />,
    url: "update",
  },
  { el: <Relawan />, url: "database/data-relawan" },
  { el: <AddRelawan />, url: "database/data-relawan/add" },
  { el: <EditRelawan />, url: "database/data-relawan/edit/:id" },
  {
    el: <RoleComp el={<ImportPemilih />} role={["1"]} />,
    url: "database/pemilih/import-data",
  },
  {
    el: <RoleComp el={<EditPemilih />} role={["1"]} />,
    url: "database/pemilih/edit-pemilih/:id",
  },
  { el: <DetailPemilih />, url: "database/pemilih/detail/:id" },
  { el: <Dashboard />, url: "dashboard" },
  { el: <RoleComp el={<Pengguna />} role={["1"]} />, url: "pengguna" },
  {
    el: <RoleComp el={<TambahPengguna />} role={["1"]} />,
    url: "pengguna/add",
  },
  {
    el: <RoleComp el={<EditPengguna />} role={["1"]} />,
    url: "pengguna/edit/:id",
  },
  {
    el: <RoleComp el={<Quickcount />} role={["1"]} />,
    url: "realcount",
  },
  {
    el: <RoleComp el={<AddQuickcount />} role={["1"]} />,
    url: "realcount/add",
  },
];

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role) ? (
    el
  ) : (
    <Navigate to="/loged/dashboard" replace />
  );
}

function App() {
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index/>
              </RequireAuth>
            }
          >
            {pages.map((page) => (
              <Route path={page.url} element={page.el} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
