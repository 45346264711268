import React from "react";
import Pagination from "@mui/material/Pagination";

export default function PaginationCustom({ state, action }) {
  const handleChange = (event, value) => {
   
    action.updateStore({
      page: value - 1,
    });
    action.loadData();
  };
  return (
    <Pagination
      count={state.total}
      page={state.page + 1}
      onChange={handleChange}
      color="primary"
    />
  );
}
