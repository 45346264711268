import { apiClient } from "../../../../service";

export const viewAllAssetApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/asset/view?${queryString}`);
};
export const viewAllStockApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/asset/stocks?${queryString}`);
};
export const viewAllDistributionApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/asset/distributions?${queryString}`);
};

export const addApi = (form) => apiClient.post(`/asset/create`, form);
export const addStock = (form) => apiClient.post(`/asset/create-stock`, form);
export const addDistribution = (form) =>
  apiClient.post(`/asset/create-distribution`, form);

export const deleteAssetApi = (id) => apiClient.post(`/asset/delete/${id}`);
export const deleteStockApi = (id) =>
  apiClient.post(`/asset/delete-stock/${id}`);
export const deleteDistributionApi = (id) =>
  apiClient.post(`/asset/delete-distribution/${id}`);

export const sebaranApi = () => apiClient.get(`/sebaran/view`);
export const detailSebaranApi = (id) => apiClient.get(`/sebaran/detail/${id}`);
