import React from "react";

export default function Privacy() {
  return (
    <div>
      <h3>KEBIJAKAN PRIVASI</h3>
      <p>
        Kami berkomitmen untuk menjaga keamanan dan kerahasiaan data pribadi
        yang diberikan oleh pengguna saat mengakses dan menggunakan Layanan.
        <br />
        <br />
        Pengguna memberikan Data Pribadi secara sukarela dan tanpa tekanan atau
        paksaan dari pihak manapun, serta bertanggung jawab penuh terhadap
        kerahasiaan Data Pribadi tersebut.
        <br />
        <br />
        Digital Prospect akan menjaga privasi data Anda dengan tidak memberikan
        informasi pribadi Pengguna, termasuk nama, alamat, nomor telepon, alamat
        email, atau informasi lainnya kepada pihak ketiga tanpa izin terlebih
        dahulu dari pelanggan yang bersangkutan, atau sebagaimana diperlukan
        dalam pengadilan yang sesuai atau instruksi administratif, termasuk
        panggilan sidang resmi, atau sebagaimana yang diperlukan untuk
        mengajukan Layanan.
        <br />
        <br />
        Namun, Digital Prospect dapat memberikan informasi account pelanggan
        kepada pihak berwenang jika diminta dalam rangka investigasi, proses
        hukum, atau pelanggaran undang-undang ITE.
        <br />
        <br />
        Dengan mengunduh, memasang, atau menggunakan Platform dan/atau menikmati
        Layanan Kami, Anda setuju bahwa Anda telah membaca, memahami,
        mengetahui, menerima, dan menyetujui seluruh informasi, syarat-syarat,
        dan ketentuan-ketentuan penggunaan Platform yang tercantum dalam
        Ketentuan Penggunaan ini.
      </p>
      <br />
      <h3>Keamanan</h3>
      Digital Prospect Indonesia berkomitmen untuk melindungi kerahasiaan dan
      keamanan data dari setiap pengunjung dan pengguna layanan kami.
      <br /> <br />
      Mohon baca Kebijakan Privasi kami secara seksama untuk memahami bagaimana
      kami mengumpulkan, menggunakan, dan melindungi data Anda. Dengan terus
      menggunakan situs web dan layanan kami, Anda mengakui bahwa Anda telah
      membaca, memahami, dan menyetujui ketentuan yang ditetapkan dalam
      Kebijakan Privasi ini.
      <br />
      <br />
      Pengumpulan Data Kami mengumpulkan data secara langsung ketika Anda
      berinteraksi dengan situs web kami. Interaksi tersebut dapat berupa
      aktivitas selama berselancar, mengisi formulir yang tersedia, mengunggah
      dokumen tertentu, dan berkomunikasi menggunakan aplikasi pihak ketiga.
      <br />
      <br />
      Beberapa informasi yang kami kumpulkan secara langsung saat Anda mendaftar
      di situs web kami adalah username, nama, email, nomor telepon, alamat dan
      paket berlangganan.
    </div>
  );
}
