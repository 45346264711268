/* eslint-disable no-unused-vars */
import { createStore, createHook } from "react-sweet-state";
// eslint-disable-next-line no-unused-vars
import {
  deleteDistributionApi,
  addDistribution,
  viewAllDistributionApi,
} from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    limit: 10,
    skip: 0,
    search: "",
    data: null,
    total: 0,
    error: null,
    page: 0,
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
      loadData:
      (id) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        const { page } = getState();
        const resp = await viewAllDistributionApi({
          id,
          page,
        });
        if (resp.data && resp.data.code === 200) {
          setState({
            data: resp.data.payload.result,
            total: resp.data.payload.totalPage,
            page: resp.data.payload.page,
          });
        }
        setState({ isLoading: false });
      },
    addData:
      (form) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await addDistribution(form);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return { ok: true };
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return { ok: false, err: err.response.data.error.message };
        }
      },
    deleteData:
      (id) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await deleteDistributionApi(id);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
  },
});

export const useDistributionStore= createHook(Store);
